import React, {useState} from 'react';
import styled from 'styled-components';
import Container from "./atoms/Container";

// Define interfaces
interface CheckmarkList {
    listItem: string;
}

export interface CommitmentModuleSpeedBlock {
    blockTitle: string;
    price3Months: string;
    price12Months: string;
    price24Months: string;
    price36Months: string;
    checkmarkList: CheckmarkList[];
}

interface PricingTableProps {
    data: CommitmentModuleSpeedBlock[];
    showDurationSwitch: boolean;
}

interface TabButtonProps {
    active: boolean;
}

type PriceFields = 'price3Months' | 'price12Months' | 'price24Months' | 'price36Months';

const PricingTable: React.FC<PricingTableProps> = ({ data, showDurationSwitch }) => {
    const [activeTab, setActiveTab] = useState(3);

    if (!data) {
        return <p>No data</p>;
    }

    const periods = ['3', '12', '24', '36'];
    const priceFields: PriceFields[] = ['price3Months', 'price12Months', 'price24Months', 'price36Months'];

    return (
        <section>
            <Container paddingTop={'5rem'} paddingBottom={'5rem'} paddingBottomL={'8.8rem'} maxWidth>
                <Title>Pricing</Title>
                <MonthsTitle>Months</MonthsTitle>
                {showDurationSwitch && (
                    <Tabs>
                        {periods.map((period, index) => (
                            <TabButton
                                key={period}
                                active={index === activeTab}
                                onClick={() => setActiveTab(index)}
                            >
                                {period}
                                <MonthsSpan>months</MonthsSpan>
                            </TabButton>
                        ))}
                    </Tabs>
                )}
                <Plans>
                    {data.map((plan: CommitmentModuleSpeedBlock) => (
                        <Plan key={plan.blockTitle}>
                            <PlanHeader>{plan.blockTitle}</PlanHeader>
                            <PlanContent>
                                <PlanPrice>
                                    <span>From</span>
                                    {plan[priceFields[activeTab]]}
                                    <span>per month</span>
                                </PlanPrice>
                                <PlanFeatures>
                                    {plan.checkmarkList.map(feature => (
                                        <li key={feature.listItem}>{feature.listItem}</li>
                                    ))}
                                </PlanFeatures>
                            </PlanContent>
                        </Plan>
                    ))}
                </Plans>
            </Container>
        </section>
    );
};

export default PricingTable;

const Title = styled.h3`
  margin-bottom: 2rem;
  text-align: center;
  font: ${(props) => props.theme.fonts.small.exchangePicker};
`;

const MonthsSpan = styled.span`
  visibility: hidden;
  display: none;   
  @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
    display: block;
    visibility: visible;
  }
`;

const MonthsTitle = styled.h4`
  visibility: hidden;
  display: none; 
  text-align: center;
  margin-bottom: 2rem;
  font: ${(props) => props.theme.fonts.small.exchangePicker};
  font-size: 2rem;
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.m}) {
    display: block;
    visibility: visible;
  }
`;

const Tabs = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  border: 1px solid #e0e0e0;
  border-radius: 2rem;
  width: fit-content;
  margin: 0 auto 3rem auto;
`;

const TabButton = styled.button<TabButtonProps>`
  padding: 1rem 2rem;
  margin: 0;
  border: none;
  background-color: ${({ active }) => (active ? '#4A4A4A' : '#ffffff')};
  color: ${({ active }) => (active ? 'white' : 'black')};
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 2rem;
  display: flex;
  text-transform: uppercase;
  gap: 0.2rem;
  font-weight: 700;

  &:hover {
    background-color: ${({ active }) => (active ? '#4A4A4A' : '#e0e0e0')};
  }`;

const PlanContent = styled.div`
  padding: 2rem;
`;

const Plans = styled.div`
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.m}) {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
      }
  display: flex;
  justify-content: center;
`;

const Plan = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  margin: 0 1rem;
  width: 25rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PlanHeader = styled.div`
  font-weight: 900;
  font-size: 1.8rem;
  color: white;
  background-color: #f47f22;
  padding: 1rem;
  border-radius: 0.5rem 0.5rem 0 0;
  text-align: center;
  font-family: urw-din;
`;

const PlanPrice = styled.div`
  font: ${(props) => props.theme.fonts.small.exchangePicker};
  margin: 0 0 2rem 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: bold;

  span {
    font-size: 1.6rem;
    color: #777;
    font-weight: normal;
  }
`;

const PlanFeatures = styled.ul`
  list-style: none;
  text-align: left;
  padding: 2rem 0 0 0;
  border-top: 1px solid #e0e0e0;

  li {
    margin: 0 0 1rem 0;

    &:before {
      content: '✔';
      color: #f47f22;
      margin-right: 0.8rem;
    }
  }
`;
